<template>
  <div class="nvr-live app-container">
    <div class="header">
      <div class="item">
        设备名称: {{name || '--'}}
      </div>
      <div class="item">
        通道ID: {{channelId}}
      </div>
    </div>
    <div class="player-container">
      <video
        class="video"
        autoplay
        :src="src"
        controls
        @canplay="onCanplay"
      ></video>
    </div>
  </div>
</template>

<script>
import { getNvrPlayUrl } from '@/api/nvr'

export default {
  data() {
    return {
      name:  this.$route.query.channelName,
      channelId: this.$route.query.channelId,
      cameraId: this.$route.query.cameraId,
      nvrId: this.$route.query.nvrId,
      src: ''
    }
  },
  mounted() {
    this.getPlayUrl()
  },
  methods: {
    async getPlayUrl() {
      const res = await getNvrPlayUrl({
        nvrId: this.nvrId,
        cameraId: this.cameraId
      })
      this.src = location.protocol === 'http:' ? res.data.fmp4 : res.data.https_fmp4
    },
    onCanplay(e) {
      e.target.play()
    }
  }
}
</script>

<style>
.nvr-live {
  .header {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    .item {
      margin-right: 40px;
    }
  }
  .player-container {
    position: relative;
    .video {
      object-fit: contain;
      position: absolute;
      top: 20px;
      left: 0;
      width: calc(100vw - 300px);
      height: calc(100vh - 240px);
    }
  }
}
</style>
